import { useStaticQuery, graphql } from 'gatsby';

const useMainPartnersPage = () => {
    const { sanityPageMainPartners: PageMainPartners } = useStaticQuery(graphql`
        query {
            sanityPageMainPartners {
                bannerImage {
                    image {
                        ...ImageWithPreview
                    }
                    alt
                }
                title {
                    en
                    se
                }
                description {
                    se {
                        _rawContent
                    }
                    en {
                        _rawContent
                    }
                }
                mainPartnersMen {
                    title
                    url
                    logo {
                        ...ImageWithPreview
                    }
                }
                mainPartnersWomen {
                    title
                    url
                    logo {
                        ...ImageWithPreview
                    }
                }
            }
        }
    `);

    return PageMainPartners;
};

export default useMainPartnersPage;
